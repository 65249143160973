import http from "@/api/http.js";

function login(username, password) {
	return http({
		url: "/UmsUser/Login",
		method: "post",
		data: {
			appKey: process.env.VUE_APP_KEY,
			username: username,
			password: password
		}
	});
}

function create(payload) {
	return http({
		url: "/UmsUser/Create",
		method: "post",
		data: payload
	});
}

function update(payload) {
	return http({
		url: "/UmsUser/Update",
		method: "post",
		data: payload
	});
}

function updateStatus(id) {
	return http({
		url: "/UmsUser/UpdateStatus/" + id,
		method: "post",
	});
}

function resetPassword(id) {
	return http({
		url: "/UmsUser/ResetPassword/" + id,
		method: "post",
	});
}

function listAll() {
	return http({
		url: "/UmsUser/ListAll",
		method: "get"
	});
}

function listSysAppByUmsUserId(id) {
	return http({
		url: "/UmsUser/RelSysApp/ListByUmsUserId/" + id,
		method: "get"
	});
}

function relateSysApp(payload) {
	return http({
		url: "/UmsUser/RelSysApp/CreateBatch",
		method: "post",
		data: payload
	});
}

function removeSysApp(userId, appIdList) {
	return http({
		url: "/UmsUser/RelSysApp/Delete",
		method: "post",
		data: {
			umsUserId: userId,
			sysAppIdList: appIdList
		}
	});
}

function listPmsErpByUmsUserId(id) {
	return http({
		url: "/UmsUser/RelPmsErp/ListByUmsUserId/" + id,
		method: "get"
	});
}

function relatePmsErp(userId, erpIdList) {
	return http({
		url: "/UmsUser/RelPmsErp/Create",
		method: "post",
		data: {
			umsUserId: userId,
			pmsErpIdList: erpIdList
		}
	});
}

function removePmsErp(userId, erpIdList) {
	return http({
		url: "/UmsUser/RelPmsErp/Delete",
		method: "post",
		data: {
			umsUserId: userId,
			pmsErpIdList: erpIdList
		}
	});
}

export default {
	login,
	create,
	update,
	updateStatus,
	resetPassword,
	listAll,
	listSysAppByUmsUserId,
	relateSysApp,
	removeSysApp,
	listPmsErpByUmsUserId,
	relatePmsErp,
	removePmsErp
};
